import * as React from 'react';
import styled from 'styled-components';
import { changeDate } from '../../ChangeDate';
import ArrowLink from '../atoms/ArrowLink';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { Post } from '../../shared/wp-post-utils';
import Img from "gatsby-image";

type LatestNewsProps = {
    post: Post;
}

const LatestNewsWrapper = styled.div`
    margin-top: 70px;
    padding: ${({ theme }) => theme.paddingDesktopDouble}; 

    @media (min-width:1245px) and (max-width: 1600px) {
        padding: ${({ theme }) => theme.paddingLaptop}; 
    }
    @media (min-width: 992px) and (max-width: 1249.98px) {
        padding: ${({ theme }) => theme.paddingLaptop}; 
        padding-bottom: 170px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: ${({ theme }) => theme.paddingTablet};
        padding-bottom: 90px;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        padding: ${({ theme }) => theme.paddingPhone}; 
        padding-bottom: 70px;
     }
`;

const Content = styled.div`
    display: flex;
    align-items: center;
     
    .latestNews__image {
        width: 600px;
        margin-right: 58px;
        border-radius: 16px;
        box-shadow: ${({ theme }) => theme.boxShadow}; 
    }

    @media (min-width: 992px) and (max-width: 1600px) {
        .latestNews__image {
            max-width: 500px;
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        flex-direction: column;
        .latestNews__image {
            margin: 0 0 25px 0;
            max-width: 100%;
        }
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        flex-direction: column;
        .latestNews__image {
            margin: 0 0 25px 0;
            max-width: 100%;
        }
    }
    
`;

const NewsContent = styled.div`
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ArticleTitle = styled.p`
    font-weight: 500;
    font-size: 34px;
    margin: 0 0 24px;
    font-family: Museo;
    white-space: normal;

    @media (min-width: 992px) and (max-width: 1600px) {
       font-size: 24px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        font-size: 23px;
        margin: 0;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        font-size: 23px;
        margin: 0;
    }
`;

const ArticleDescription = styled.p`
    font-size: 18px;
    margin: 0 0 24px;
`;

const ArticleDate = styled.p`
    font-size: 16px;
    margin: 0 0 24px;
    font-family: Museo;
    font-weight: 300;
`;

const ArrowWrapper = styled.div`
    width: 200px;
    overflow-x: hidden;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${({ theme }) => theme.blackColor};
`;
 
const LatestNews: React.FC<LatestNewsProps> = ({post: {url, image, date, summary, title}}) => {
    const {t} = useTranslation();

    return ( 
        <LatestNewsWrapper>
            <Content>
                <Link to={`${url}/`}><Img className="latestNews__image" fixed={image} alt="obraz newsa" loading="lazy"/></Link>
                <NewsContent>
                    <ArticleDate>{changeDate(date)}</ArticleDate>
                    <StyledLink to={`${url}/`}><ArticleTitle>{title}</ArticleTitle></StyledLink>
                    <ArticleDescription>{summary}</ArticleDescription>
                    <ArrowWrapper>
                        <ArrowLink textWidth={200} link={url}>{t('readMore')}</ArrowLink>
                    </ArrowWrapper>
                </NewsContent>
            </Content>
        </LatestNewsWrapper>
     );
}
 
export default LatestNews;
