import * as React from 'react';
import styled from 'styled-components';
import { changeDate } from '../../ChangeDate';
import ArrowLink from '../atoms/ArrowLink';
import { Tag } from '../../layouts/NewsPage';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { Post } from '../../shared/wp-post-utils';
import Img from "gatsby-image";

type NewsCardProps = {
    post: Post;
}


const Card = styled.div`
    width: 33.333%;
    display: flex;
    flex-direction: column;
    margin-bottom: 90px;
    padding: 0 47px;

    .newsCard__image {
        max-width: 100%;
        max-height: 220px;
        margin: 0 0 24px;
        border-radius: 16px;
        box-shadow: ${({ theme }) => theme.boxShadow}; 
    }

    @media (min-width: 992px) and (max-width: 1600px) {
        width: 33.333%;
        padding: 0 30px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        width: 50%;
        padding: 0 30px;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        width: 100%;
        padding: 0;
        margin-bottom: 60px;
    }

    @media (min-width: 1400px) and (max-width: 1600px) {
        .newsCard__image {
            max-height: 215px;
        }
    }
    @media (min-width: 1200px) and (max-width: 1400px) {
        .newsCard__image {
            max-height: 180px;
        }
    }
    @media (min-width: 1100px) and (max-width: 1300px) {
        .newsCard__image {
            max-height: 160px;
        }
    }
    @media (min-width: 992px) and (max-width: 1100px) {
        .newsCard__image {
            max-height: 140px;
        }
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
        .newsCard__image {
            max-height: 160px;
        }
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        .newsCard__image {
            height: 200px;
        }
    }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${({ theme }) => theme.blackColor};
`;

const ArticleTitle = styled.p`
    font-weight: 400;
    font-size: 22px;
    margin: 0 0 24px;
    font-family: Museo;
    white-space: normal;
    line-height: 1.4;
`;

const ArticleDescription = styled.p`
    font-size: 18px;
    margin: 0 0 24px;
    line-height: 1.6;
`;

const ArticleDate = styled.p`
    font-size: 16px;
    font-family: Museo;
    font-weight: 300;
    margin: 0 0 24px;
`;

const ArrowWrapper = styled.div`
    width: 200px;
    height: 53px;
    overflow-x: hidden;
`;




 
const NewsCard: React.FC<NewsCardProps> = ({post: { url, image, date, title, summary, category }}) => {
    const {t} = useTranslation();

    return ( 
        <Card>
            <Link to={`${url}/`}><Img className="newsCard__image" fixed={image} alt="news image" loading="lazy"/></Link>
            <ArticleDate>{changeDate(date)}</ArticleDate>
            <Tag>{category === "News" ? "Artykuł" : category}</Tag>
            <StyledLink to={`${url}/`}><ArticleTitle>{title}</ArticleTitle></StyledLink>
            <ArticleDescription>{summary}</ArticleDescription>
            <ArrowWrapper>
                <ArrowLink textWidth={200} link={url}>{t('readMore')}</ArrowLink>
            </ArrowWrapper>
        </Card>
     );
}
 
export default NewsCard;