import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const HeaderNews = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	margin-bottom: 65px;
	align-items: center;

	@media (min-width: 1px) and (max-width: 767.98px) {
        flex-direction: column;
	    align-items: flex-start;
	    margin-bottom: 50px;
    }
`;

const Title = styled.h2`
	flex-basis: 50%;
	color: ${({ theme }) => theme.primaryColor};
	font-size: 46px;
	margin: 0;
	line-height: 1;
	word-wrap: break-word;

	@media (min-width: 1px) and (max-width: 1189.98px) {
       font-size: 40px;
	   line-height: 1.3;
    }
`;


const AllNewsHeading: React.FC = () => {
	const {t} = useTranslation();
	
    return ( 
        <HeaderNews>
            <Title>{t('allNews')}</Title>
        </HeaderNews>
    );
}
 
export default AllNewsHeading;