import * as React from 'react';
import styled, { css } from 'styled-components';
import { Link } from "gatsby";
import ArrowLeft from '../../images/paginationArrow-left.svg';
import ArrowRight from '../../images/paginationArrow-right.svg';

type PaginationButtonsProps = {
    numPages: number;
    currentPage: number;
}

type ButtonLinkProps = {
    disabled?: boolean;
    $isActive?: boolean;
}

const StyledPaginationButtons = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    flex-wrap: wrap;

    @media (min-width: 1200px) and (max-width: 1600px) {
        padding: ${({ theme }) => theme.paddingLaptop};
    }

    @media (min-width: 992px) and (max-width: 1199.98px) { 
        padding: ${({ theme }) => theme.paddingLaptop}; 
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: ${({ theme }) => theme.paddingTablet};
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        padding: ${({ theme }) => theme.paddingPhone}; 
    }
`

const ButtonLink = styled(Link)<ButtonLinkProps>`
   border-radius: 8px;
   font-family: Museo;
   font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 51px;
    height: 51px;
    text-decoration: none;
    font-weight: 400;
    margin: 12px 9px;
    color: ${({ theme }) => theme.blueColor};
    background-color: ${({ theme }) => theme.whiteColor};
    border: 2px solid ${({ theme }) => theme.blueColor};

    :hover {
        background-color: ${({ theme }) => theme.blueLightColor};
    }

    :focus {
       outline: none;
    }

   ${({ $isActive }) =>
        $isActive &&
        css`
        color: ${({ theme }) => theme.whiteColor};
        background-color: ${({ theme }) => theme.blueColor};
        :hover {
            background-color: ${({ theme }) => theme.blueHoverColor};
        }
    `}


   ${({ disabled }) =>
        disabled &&
        css`
        cursor: inherit;
        pointer-events: none;
        opacity: 0.3;
    `}
`;

const StyledImage = styled.img`
    display: block;
    width: 9px;
    height: 15px;
`;

const PaginationButtons: React.FC<PaginationButtonsProps> = ({ numPages, currentPage}) =>  {
   return (
   <StyledPaginationButtons>
        <ButtonLink to={currentPage === 2 ? `/aktualnosci/` : `/aktualnosci/${currentPage - 1}/`} disabled={currentPage === 1 ? true : false}>
            <StyledImage src={ArrowLeft} alt="strzałka w lewo"/>
        </ButtonLink>
        {
            [...Array(numPages).keys()].map((number, index) => 
                 <ButtonLink key={index} to={number === 0 ? `/aktualnosci/` : `/aktualnosci/${number + 1}/`} $isActive={number + 1 === currentPage ? true : false}>{number + 1}</ButtonLink>
                )
        }
        <ButtonLink to={`/aktualnosci/${currentPage + 1}/`} disabled={currentPage === numPages ? true : false} >
            <StyledImage src={ArrowRight} alt="strzałka w prawo" />
        </ButtonLink>
    </StyledPaginationButtons>
    )
}

export default PaginationButtons;
