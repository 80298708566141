import { graphql, StaticQuery } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import newsBackground from "../../images/news-background.jpg";
import LatestNews from './LatestNews';
import newsImage from "../../images/aktualnosci.svg";
import { Post } from '../../shared/wp-post-utils';


type HeaderNewsProps = {
    title: string;
    post: Post;
}

const Background = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image:url(${newsBackground});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    margin-bottom: 340px;


    @media (min-width: 1250px) and (max-width: 1600px) {
        margin-bottom: 340px;    
        height: 130vh;
    }

    @media (min-width: 992px) and (max-width: 1249.98px) {
        margin-bottom: 0px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        margin-bottom: 0px;  
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        background-position: top right;
        background-size: 250%;
        height: 77vh;
        margin-bottom: 0px;
    }
`;

const StyledImage = styled.img`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 258px;

    @media (min-width: 1px) and (max-width: 767.98px) {
        width: 100%;
    }
`;

const Title = styled.h1`
    margin: 37.5px 20px 13px 0;
    color: ${({ theme }) => theme.primaryColor};

    @media (min-width: 1px) and (max-width: 767.98px) {
        margin: 0 0 40px;
    }
`;

const Desktop = styled.div`
    text-align: center;
    
    @media (min-width: 1px) and (max-width: 767.98px) {
        display: none;
    }
`;

const Mobile = styled.div`
    display: none;

    @media (min-width: 1px) and (max-width: 767.98px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: ${({ theme }) => theme.paddingPhone}; 
    }
`;

const LatestNewsWrapperHeader = styled.div`
    width: 100%;
    position: absolute;
    bottom: -16%;
    margin-top: 70px;

    @media (min-width: 992px) and (max-width: 1249.98px) {
        display: none;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        display: none;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        display: none;
    }
`;

const LatestNewsWrapperContent = styled.div`
    display: none;

    @media (min-width: 992px) and (max-width: 1249.98px) {
        display: block;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        display: block;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        display: block;
    }


`;

const HeaderNews: React.FC<HeaderNewsProps> = ( {title, post} ) => {

    return ( 
        <>
            <Background>
                <Desktop>
                    <Title>{title}</Title>
                </Desktop>
                <StyledImage src={newsImage} alt="Aktualnosci" loading="eager"/>
                <LatestNewsWrapperHeader>
                    <LatestNews post={post}/>
                </LatestNewsWrapperHeader>
            </Background>
            <Mobile>
                <Title>{title}</Title>
            </Mobile>
            <LatestNewsWrapperContent>
                <LatestNews post={post}/>
            </LatestNewsWrapperContent>
        </>
     );
}
 
export default HeaderNews;