import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import styled from 'styled-components';
import { Post } from '../../shared/wp-post-utils';
import AllNewsHeading from '../molecules/AllNewsHeading';
import NewsCard from './NewsCard';

type NewsGalleryProps = {
	searchNews: string;
	posts: Post[];
}

const GridWrapper = styled.div`
	margin-top: 170px;
	padding: ${({ theme }) => theme.paddingDesktopDouble}; 

    @media (min-width: 992px) and (max-width: 1600px) {
        padding: ${({ theme }) => theme.paddingLaptop}; 
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: ${({ theme }) => theme.paddingTablet};
		margin-top: 90px;
    }

    @media (min-width: 1px) and (max-width: 767.98px) {
        padding: ${({ theme }) => theme.paddingPhone}; 
		margin-top: 70px;
     }
`;

const NewsGrid = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin: 0 -47px;

	@media (min-width: 992px) and (max-width: 1600px) {
        margin: 0 -30px;
    }

	@media (min-width: 768px) and (max-width: 991.98px) {
        margin: 0 -30px;
    }

	@media (min-width: 1px) and (max-width: 767.98px) {
       margin: 0;
     }

	 h4 {
		 width: 100%;
		 text-align: center;
		 margin-bottom: 80px;
	 }
`;
 
const NewsGallery: React.FC<NewsGalleryProps> =({posts: data, searchNews}) => {
    const {t} = useTranslation();
	const cards = data.map(post => <NewsCard key={post.url} post={post}/>);

    return ( 
        <GridWrapper>
            <AllNewsHeading/>
            <NewsGrid>
                {cards.length > 0 ? cards : <h4>{t('postNotFound')}</h4>}
            </NewsGrid>
        </GridWrapper>
     );
}
 
export default NewsGallery