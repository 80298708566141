import * as React from 'react';
import { useMemo, useState } from 'react';
import Layout from "../components/layout"
import { graphql } from 'gatsby';
import NewsGallery from '../components/organisms/NewsGallery';
import PageContent from '../components/organisms/PageContent';
import HeaderNews from '../components/organisms/HeaderNews';
import { SearchBar } from '../components/atoms/SearchBar';
import styled from 'styled-components';
import SEO from '../components/seo';
import PaginationButtons from '../components/atoms/PaginationButtons';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import { WpPost, getPostModel } from '../shared/wp-post-utils';

type NewsProps = {
	data: {
		allWpPost: {
			nodes: WpPost[];
		}
	}
	pageContext: {
		limit: number,
        skip: number,
        numPages: number,
        currentPage: number,
	};
}

const StyledSearchBar = styled.div`
	@media (min-width: 1px) and (max-width: 767.98px) {
        padding: ${({ theme }) => theme.paddingPhone}; 
    }
`;

const NewsListPage: React.FC<NewsProps> = ({ data: pageData, pageContext }) => {
	const {t} = useTranslation();
	const [query, setQuery] = useState('');
	const showFilteredPosts = useMemo(() => query.length >= 3, [query]);

	const handleSearchNews = debounce((inputText: string) => {
			setQuery(inputText)
    }, 500);

	const posts = useMemo(() => pageData.allWpPost.nodes.map(getPostModel), [pageData]);
	const currentPosts = useMemo(() => {
		if (!showFilteredPosts) {
			return posts.slice((pageContext.currentPage - 1) * pageContext.limit, pageContext.currentPage * pageContext.limit)
		}
		return posts.filter(post => post.title.toLowerCase().includes(query.toLowerCase()));
	}, [query, showFilteredPosts, posts, pageContext.currentPage, pageContext.limit]);

    return ( 
		<Layout>
			<SEO title={t('news')} description={t('news')}/>
			<HeaderNews title={t('news')} post={posts[0]}/>
			<PageContent>
				<StyledSearchBar>
					<SearchBar handleSearchNews={handleSearchNews}/>
				</StyledSearchBar>
				<NewsGallery posts={currentPosts} searchNews={query}/>
				{query.length < 3 ? <PaginationButtons numPages={pageContext.numPages} currentPage={pageContext.currentPage}/> : null }
			</PageContent>
		</Layout>
    );
}

export default NewsListPage;

export const pageQuery = graphql`
query newsListQuery($language: String!) {
	allWpPost(
		sort: {fields: dateGmt, order: DESC}
		filter: {categories: {nodes: {elemMatch: {name:  { regex: "/(News)|(Projekt)/" }}}}, language: {slug: {eq: $language}}}
		) {
		nodes {
			title
			content
			dateGmt
			databaseId
            language {
              	slug
            }
            translations {
              	databaseId
              	language {
                	slug
              	}
            }
			categories {
				nodes {
					name
				}
			}
			...NewsImageMedium
		}
	}
	locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}
`